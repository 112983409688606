import {
  FC,
  // useEffect,
  useState,
  useMemo,
  useEffect,
} from 'react';
// import { useNavigate, useLocation } from 'react-router-dom';
import type { MenuProps } from 'antd';
import { Dropdown } from 'antd';
import { UserOutlined, DownOutlined } from '@ant-design/icons';
import { useAuthContext } from 'context/AuthContext';
import { useLogout } from 'hooks/useAuth';
import { useRouteContext } from 'context/RouteContext';

const UserMenu: FC = () => {
  // const navigate = useNavigate();
  // const { pathname } = useLocation();
  const logout = useLogout();
  const { user } = useAuthContext();
  const { setPath, path } = useRouteContext();
  const [activeMenuItem, setActiveMenuItem] = useState<string>();

  // useEffect(() => {
  //   // setActiveMenuItem([pathname]);
  //   console.log(user);
  // }, [user]);

  // const menu = useMemo(
  //   () => (
  //     <Menu
  //       onClick={(e) => setActiveMenuItem([e.key])}
  //       // defaultSelectedKeys={activeMenuItem}
  //       selectedKeys={activeMenuItem}
  //     >
  //       <Menu.Item key="/" onClick={() => setPath('/')}>
  //         Home
  //       </Menu.Item>

  //       <Menu.Item key="/services" onClick={() => setPath('/services')}>
  //         Services
  //       </Menu.Item>

  //       <Menu.Item key="/subscribers" onClick={() => setPath('/subscribers')}>
  //         Subscribers
  //       </Menu.Item>

  //       <Menu.Item
  //         key="/estimate-requests"
  //         onClick={() => setPath('/estimate-requests')}
  //       >
  //         Estimate Requests
  //       </Menu.Item>

  //       <Menu.Item key="/pages" onClick={() => setPath('/pages')}>
  //         Pages
  //       </Menu.Item>

  //       <Menu.Item key="/text-blocks" onClick={() => setPath('/text-blocks')}>
  //         Text blocks
  //       </Menu.Item>

  //       <Menu.Item key="/meta" onClick={() => setPath('/meta')}>
  //         Meta
  //       </Menu.Item>

  //       <Menu.Item key="/resources" onClick={() => setPath('/resources')}>
  //         Media
  //       </Menu.Item>

  //       <Menu.Item key="/settings" onClick={() => setPath('/settings')}>
  //         Settings
  //       </Menu.Item>

  //       <Menu.Item key="/user-sign-out" onClick={async () => await logout()}>
  //         Sign out
  //       </Menu.Item>
  //     </Menu>
  //   ),
  //   []
  // );

  const items: MenuProps['items'] = [
    {
      key: 'home',
      label: 'Home',
      onClick: () => setPath('/'),
    },
    {
      key: 'services',
      label: 'Services',
      onClick: () => setPath('/services'),
    },
    {
      key: 'orders',
      label: 'Orders',
      onClick: () => setPath('/orders'),
    },
    {
      key: 'feedback',
      label: 'Feedback',
      onClick: () => setPath('/feedback'),
    },
    // {
    //   key: 'text-blocks',
    //   label: 'Text blocks',
    //   onClick: () => setPath('/text-blocks'),
    // },
    {
      key: 'meta',
      label: 'Meta',
      onClick: () => setPath('/meta'),
    },
    {
      key: 'resources',
      label: 'Resources',
      onClick: () => setPath('/resources'),
    },
    {
      key: 'settings',
      label: 'Settings',
      onClick: () => setPath('/settings'),
    },
    {
      key: 'user-sign-out',
      label: 'Sign out',
      onClick: async () => await logout(),
    },
  ];

  useEffect(() => {
    console.log({ path });

    setActiveMenuItem(path?.split('/')[1]);
  }, []);

  return (
    <Dropdown
      menu={{
        items,
        selectable: true,
        // defaultSelectedKeys: ['home'],
        onClick: (e) => setActiveMenuItem(e.key),
        activeKey: activeMenuItem,
      }}
      placement="bottomRight"
      arrow
    >
      <a
        href="/"
        className="ant-dropdown-link user-profile-menu-link"
        onClick={(e) => e.preventDefault()}
      >
        {/* Welcome aboard, */}
        <UserOutlined /> Hello admin &nbsp;
        {user?.lastname}
        &nbsp;
        <DownOutlined />
        {/* <Avatar
                            src={
                              <Image
                                src={'/default-avatar.png'}
                                // fallback={'/default-avatar.png'} it doesn't work :/
                                preview={false}
                              />
                            }
                          /> */}
      </a>
    </Dropdown>
  );
};

export { UserMenu };
