import {
  BellOutlined,
  // CheckOutlined,
  DeleteOutlined,
  DollarOutlined,
  // EyeOutlined,
  LoadingOutlined,
  // PhoneOutlined,
  WhatsAppOutlined,
} from '@ant-design/icons';
import {
  Avatar,
  Badge,
  Button,
  Drawer,
  Empty,
  List,
  Spin,
  // message,
} from 'antd';
import { FC, useEffect, useMemo, useState } from 'react';
import VirtualList from 'rc-virtual-list';
import Data from 'helpers/data';
import { useRouteContext } from 'context/RouteContext';
import dataDeleteService from 'services/data/dataDeleteService';
import dataUpdateService from 'services/data/dataUpdateService';

interface INotifications {
  // isOpen: boolean;
  refresh: any;
}

interface INotification {
  readonly _id: string;
  readonly createdAt: string;
  group: string;
  message: string;
  status: string;
  metadata: Record<string, string>;
}

// const ContainerHeight = 400;

const Notifications: FC<INotifications> = ({ refresh }) => {
  const { setPath } = useRouteContext();
  const [open, setOpen] = useState(false);
  const [isLoading, setIsLoading] = useState(true);
  const { REACT_APP_API_URL } = process.env;
  const data = new Data<INotification>(`${REACT_APP_API_URL}/notifications`);
  const [notifications, setNotifications] = useState<INotification[]>([]);

  const getNotifications = useMemo(
    () => async () => {
      setIsLoading(true);

      const response = await data.read({
        page: 1,
        limit: 100,
        fields: ['group', 'message', 'status', 'metadata'],
        sort: 'createdAt:desc',
      });

      console.log(response);

      setNotifications(response.data.items);

      setIsLoading(false);
    },
    [refresh]
  );

  useEffect(() => {
    console.log(refresh?.total);

    if (refresh && open) {
      // alert('FETCH NOTIFICATIONS');
      getNotifications();
    }
  }, [refresh, open]);

  const showDrawer = () => {
    setOpen(true);
    // getNotifications();
  };

  const onClose = () => {
    setOpen(false);
  };

  const onScroll = (e: React.UIEvent<HTMLElement, UIEvent>) => {
    // if (
    //   e.currentTarget.scrollHeight - e.currentTarget.scrollTop ===
    //   ContainerHeight
    // ) {
    //   appendData();
    // }
  };

  // const getNewCount = () => {};

  const showItem = async (item: INotification) => {
    item?.group === 'orders'
      ? setPath(`/orders/${item?.metadata?._id}`)
      : setPath(`/feedback/${item?.metadata?._id}`);

    await dataUpdateService<INotification>({
      requestUrl: `${REACT_APP_API_URL}/notifications`,
      options: {
        body: { ...item, status: 'seen' },
      },
    });
    setOpen(false);
  };

  const deleteItems = async (ids: string[] = []) => {
    await dataDeleteService({
      requestURL: `${REACT_APP_API_URL}/notifications`,
      where: {
        ids,
      },
      confirmDialog: false,
      feedback: false,
    });

    getNotifications();
  };

  useEffect(() => {
    console.log({ open });
  }, [open]);

  useEffect(() => {
    console.log({ open });
    setOpen(false);
  }, []);

  return (
    <>
      <div className="notifications-icon">
        <Badge count={refresh?.total}>
          <Button
            icon={<BellOutlined />}
            type="link"
            size="large"
            onClick={() => showDrawer()}
          >
            {/* &nbsp; */}
          </Button>
        </Badge>
      </div>
      <Drawer
        className="notifications"
        title="Notifications"
        extra={
          notifications ? (
            <Button type="link" onClick={() => deleteItems()}>
              Clear all
            </Button>
          ) : undefined
        }
        placement="right"
        onClose={onClose}
        open={open}
      >
        <Spin
          tip="Fetching notifications..."
          indicator={<LoadingOutlined style={{ fontSize: 24 }} spin />}
          spinning={isLoading}
        >
          {(notifications && (
            <List>
              <VirtualList
                data={notifications}
                // height={ContainerHeight}
                itemHeight={47}
                itemKey="email"
                onScroll={onScroll}
              >
                {(item) => (
                  <List.Item
                    key={item?._id}
                    onClick={() => showItem(item)}
                    actions={[
                      // <Button type="link">
                      //   <EyeOutlined />
                      // </Button>,
                      // <Button
                      //   type="link"
                      //   onClick={(e) => {
                      //     e.stopPropagation();
                      //     alert('SEEN!');
                      //   }}
                      // >
                      //   <CheckOutlined />
                      // </Button>,
                      <Button
                        type="link"
                        onClick={(e) => {
                          e.stopPropagation();
                          deleteItems([item?._id]);
                        }}
                      >
                        <DeleteOutlined />
                      </Button>,
                    ]}
                  >
                    <List.Item.Meta
                      key={item?._id}
                      avatar={
                        <Avatar
                          size={{
                            xs: 64,
                            sm: 64,
                            md: 64,
                            lg: 64,
                            xl: 64,
                            xxl: 100,
                          }}
                          style={{
                            backgroundColor: 'transparent',
                            color: item?.status === 'seen' ? '#ccc' : '#0096dc',
                          }}
                          icon={
                            item?.group === 'orders' ? (
                              <DollarOutlined />
                            ) : (
                              <WhatsAppOutlined />
                            )
                          }
                        />
                      }
                      title={<h3>{item?.message}</h3>}
                      description={'click to see details'}
                    />
                    {/* <div>Content</div> */}
                  </List.Item>
                )}
              </VirtualList>
            </List>
          )) || <Empty image={Empty.PRESENTED_IMAGE_SIMPLE} />}
        </Spin>
      </Drawer>
    </>
  );
};

export default Notifications;
