import {
  Dispatch,
  SetStateAction,
  createContext,
  useContext,
  useState,
  // useEffect,
  useMemo,
  useEffect,
} from 'react';
import Cookies from 'js-cookie';
import IUser from 'types/IUser';
import Data from 'helpers/data';
// import { useLogout } from 'hooks/useAuth';

interface IAuth {
  isLogedIn: boolean;
  setIsLogedIn: Dispatch<SetStateAction<boolean>>;
  user: IUser;
  setUser: Dispatch<SetStateAction<IUser>>;
}

const AuthContext = createContext<IAuth>({
  isLogedIn: false,
  setIsLogedIn: () => {},
  user: {},
  setUser: () => {},
});

const useAuthContext = () => {
  return useContext(AuthContext);
};

const AuthContextProvider = ({ children }: any) => {
  const [isForward, setIsForward] = useState<boolean>(false);
  const [isLogedIn, setIsLogedIn] = useState<boolean>(false);
  const [user, setUser] = useState<IUser>({});
  // const logout = useLogout();
  // const expires_in = Cookies.get('expires_in');

  // Fetch user data from API.
  // const fetchUserData = async () => {
  // console.log(user);
  // const data = new Data<IUser>(
  //   `${process.env.REACT_APP_API_URL}/users/profile`
  // );
  // const response = await data.read({
  //   fields: ['firstname', 'lastname', 'email', 'role', 'username'],
  //   limit: 1,
  // });
  // if (!response?.data?.user || response?.data?.user?.role !== 'admin') {
  //   logoutHandler();
  // }

  // console.log(response);

  // setUser(response.data.items[0]);
  // setUser({});
  // setIsForward(true);
  // }
  // console.log(user);
  // console.log(location);
  // setActiveMenuItem([location.pathname]);
  // };

  // useEffect(() => {
  //   setIsForward(true);
  // }, [user]);

  useEffect(() => {
    console.log('AuthContext useEffect!!!');
    // console.log(isLogedIn);

    const expires_in = Cookies.get('expires_in');

    if (!expires_in) {
      // setIsForward(true);

      return;
    }
    console.log({ expires_in });
    console.log(Date.now());
    console.log(Date.now() < +expires_in);

    // setIsLogedIn(false);

    // console.log(Date.now());

    setIsLogedIn(Date.now() < +expires_in);

    // isLogedIn && fetchUserData();

    // setTimeout(async () => {
    //   console.log('AUTOLOGOUT HANDLER');

    //   await logout();
    //   // setIsLogedIn(false);
    // }, +expiresIn - Date.now());

    // console.log({ isLogedIn });
  }, [isLogedIn]);

  return (
    // the Provider gives access to the context to its children
    <AuthContext.Provider value={{ isLogedIn, setIsLogedIn, user, setUser }}>
      {children}
    </AuthContext.Provider>
  );
};

export { AuthContextProvider, useAuthContext };
