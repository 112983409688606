import { Modal, notification, Typography } from 'antd';
import { ExclamationCircleOutlined } from '@ant-design/icons';
import Data, { IDataDeleteOptions } from 'helpers/data';

interface IDataDeleteService {
  requestURL: string;
  title?: string;
  message?: string;
  description?: string;
  where: IDataDeleteOptions['where'];
  confirmDialog?: boolean;
  feedback?: boolean;
}

interface IDataDelete {
  requestURL: string;
  message?: string;
  description?: string;
  where: IDataDeleteOptions['where'];
  feedback?: boolean;
}

const { confirm } = Modal;

const Delete = async ({
  requestURL,
  where,
  message,
  description,
  feedback,
}: IDataDelete) => {
  await new Data(requestURL).delete({ where });

  if (feedback) {
    // Show notification
    notification.success({
      message,
      description,
      placement: 'bottomRight',
    });
  }

  // if (callback) {
  //   callback();
  // }
};

const { Text } = Typography;

const dataDeleteService = async ({
  requestURL,
  title,
  message,
  description,
  where,
  confirmDialog = true,
  feedback = true,
}: // callback = null,
// confirmDialog = true,
IDataDeleteService) => {
  const deletePromise = new Promise(async (resolve, reject) => {
    if (confirmDialog) {
      confirm({
        title,
        icon: <ExclamationCircleOutlined />,
        content: (
          <Text type="warning">Warning! This action cant be reversed!</Text>
        ),
        okText: 'Yes',
        okType: 'danger',
        cancelText: 'No',
        async onOk() {
          await Delete({
            requestURL,
            where,
            message,
            description,
            //  callback
          });
          resolve(true);
        },
        onCancel() {
          console.log('Cancel');
          // reject('Cancel');
        },
      });
    } else {
      await Delete({
        requestURL,
        where,
        message,
        description,
        feedback,
        //  callback
      });

      resolve(true);
    }
  });

  await deletePromise;
};

export default dataDeleteService;
