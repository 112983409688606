/**
 * Class AppError extends base Error class for add
 * extra functionality like add status code value in error
 * message.
 */

interface IAppError {
  statusCode: number;
  message: string;
}

class AppError extends Error implements IAppError {
  private status: string;
  statusCode: number;
  message: string;

  constructor({ statusCode, message }: IAppError) {
    super();

    this.status = 'error';
    this.statusCode = statusCode;
    this.message = message;
  }
}

export default AppError;
