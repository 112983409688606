import { notification } from 'antd';
import Data, { IDataUpdateOptions } from 'helpers/data';

interface IDataUpdateService<S> {
  requestUrl: string;
  message?: string;
  description?: string;
  options: IDataUpdateOptions<S>;
}

const dataUpdateService = async <S>({
  requestUrl,
  message = 'Updated',
  description,
  options,
}: IDataUpdateService<S>): Promise<boolean> =>
  new Promise(async (resolve, reject) => {
    // Update item
    await new Data<S>(requestUrl).update(options);

    // Show notification
    description &&
      notification.success({
        message,
        description,
        placement: 'bottomRight',
      });

    resolve(true);
  });

export default dataUpdateService;
