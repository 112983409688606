import { useEffect, useState } from 'react';
// import logo from '../assets/images/logo.svg';
// import useSettings from './useSettings';
import Data from 'helpers/data';
import { useAuthContext } from 'context/AuthContext';

const useNotifications = () => {
  // const settings = useSettings();
  const { isLogedIn } = useAuthContext();
  const { REACT_APP_API_URL } = process.env;
  const data = new Data<any>(`${REACT_APP_API_URL}/notifications/subscribe`);
  const [notification, setNotification] = useState<any>();

  useEffect(() => {
    // if (!('Notification' in window)) {
    //   console.log('Browser does not support desktop notification');
    // } else {
    //   Notification.requestPermission();
    connect();
    // }

    if ('Notification' in window && isLogedIn) {
      Notification.requestPermission().then((permission) => {
        // updatePrompt();
        if (permission === 'granted') {
          console.log('Notification permission granted.');
          initServiceWorker();
        } else if (permission === 'denied') {
          console.warn('Notification permission denied.');
        }
      });
    }
  }, [isLogedIn]);

  const initServiceWorker = async () => {
    if ('serviceWorker' in navigator) {
      const swRegistration = await navigator.serviceWorker.register(
        new URL(
          '../components/Notifications/notifications-worker.ts',
          import.meta.url
        )
      );

      console.log({ swRegistration });

      const subscription = await swRegistration.pushManager.getSubscription();
      if (subscription) {
        console.log('User is already subscribed:', subscription);
        subscribe(subscription);
      } else {
        // const vapidPublicKey =
        //   'BECmxnj55yM-NJ0YSwGR1Bap0flr4HRqbnNMDQy0ivxntuhD8haKVpG6vgX96n4jKxDklLPpOKdyozVqhzAfaWg';

        const vapidPublicKey = process.env.REACT_APP_VAPID_PUBLIC_KEY;
        const subscription = await swRegistration.pushManager.subscribe({
          userVisibleOnly: true,
          applicationServerKey: vapidPublicKey,
        });
        console.log(JSON.stringify(subscription.toJSON()));
        subscribe(subscription);

        // console.log({ self });

        // self.registration.postMessage(JSON.stringify(subscription.toJSON()));
      }
    } else {
      console.warn('Service worker is not supported');
    }

    // navigator.serviceWorker.addEventListener("message", (e) => {
    //   const infoDiv = document.getElementById("info");
    //   infoDiv.textContent = e.data;
    // });
  };

  const subscribe = async (subscription: any) => {
    const response = await data.create({
      body: subscription.toJSON(),
    });

    console.log({ response });
  };

  useEffect(() => {
    // notification?.metadata && showNotification();
  }, [notification]);

  function connect() {
    const { REACT_APP_WEBSOCKET_URL } = process.env;

    if (!REACT_APP_WEBSOCKET_URL) {
      console.log(
        'REACT_APP_WEBSOCKET_URL is undefined. Please check your configuration.'
      );
      return;
    }

    // const url = new URL(process.env.REACT_APP_API_URL || '');
    const ws = new WebSocket(
      // `ws://${url.hostname}:${url.port}/ws`,
      REACT_APP_WEBSOCKET_URL,
      'notifications'
    );

    ws.onopen = function () {
      console.log('Connected');
    };

    ws.onmessage = function (e) {
      console.log(e.data);

      const notification = JSON.parse(e.data);

      console.log({ notification });

      notification && setNotification(notification);
    };

    ws.onclose = function (e) {
      connect();
    };
  }

  // // const showNotification = () => {
  // //   const options = {
  // //     body: notification?.metadata?.message,
  // //     icon: logo,
  // //   };

  // //   new Notification(settings?.siteName ?? '', options);
  // // };

  return { notification };
};

export default useNotifications;
