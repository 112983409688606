import { FC, useLayoutEffect, useEffect, useState, createElement } from 'react';
import { useRouteContext } from 'context/RouteContext';
import { useAuthContext } from 'context/AuthContext';
import { info, error, debug } from 'helpers/logger';

interface IFirewall {
  children: any;
  config: {
    fallback: string;
    rules: {
      path: string;
      roles: string[];
      fallback?: string;
      forward?: string;
      redirect?: string;
    }[];
  };
}

const Firewall: FC<IFirewall> = ({ children, config }) => {
  const { isLogedIn, user } = useAuthContext();
  const { path, setPath } = useRouteContext();
  const [isAllowed, setIsAllowed] = useState(false);
  const [forward, setForward] = useState<string>();

  // const isRoleAllowed = () => {};

  useEffect(() => {
    info('FIREWALL IS ACTIVE');

    // new Promise((resolve, reject) => {
    // debug({ isLogedIn, user });
    // console.log(user?.role);
    // console.log(path);

    if (!path) {
      throw new Error('Path is undefined.');
    }

    // const rule = list.filter((rule) => rule.path === path)[0];
    const rule = config.rules.find((rule) => {
      const regexp = new RegExp(`^(${rule.path})+((/[a-z0-9]+)?)+$`);

      // info(regexp.toString());
      // info(regexp.test(path).toString());

      return regexp.test(path);
    });

    // debug(rule);

    // const { role } = user;

    // if (path && !rule) {
    //   throw new Error('You dont have access to this page!');
    // }

    // if (rule && !rule.roles) {
    //   setIsAllowed(true);
    // }

    // if rule.roles = []
    // if user ||

    if (
      rule &&
      (!rule.roles.length ||
        (user.role && rule.roles && rule.roles.includes(user.role)))
    ) {
      // info('You have access to this page!');
      // setPath(rule.fallback || 'google.com');
      // return;
      // throw new Error('You dont have access to this page!');

      setIsAllowed(true);

      console.log({ path });

      console.log('forward', rule.forward);

      if (rule?.redirect) {
        setPath(rule.redirect);
      }

      // if (user.role) {
      //   rule.forward ? setPath(rule.forward) : setPath(forward);
      // }
    } else {
      setIsAllowed(false);

      console.log({ path });

      setForward(path);

      rule?.roles
        ? error(
            'This path is available only for %s roles',
            rule?.roles.join(', ')
          )
        : error('This path is available only for auth users.');

      // rule?.fallback ? setPath(rule.fallback) : setPath(config.fallback);
    }

    // });
  }, [path, user]);

  // useEffect(() => {
  // console.log({ isAllowed });

  //   //   //   console.log('Firewall path', path);
  //   //   //   // if (path === '/services/62ec5847a1c9fb710b4d8b6d') {
  //   //   //   //   throw new Error('You dont have access to this page!');
  //   //   //   // }
  // }, [isAllowed]);

  return isAllowed ? children : 'YOU DONT HAVE ACCESS TO THIS PAGE';

  // return isAllowed && children;
};

export default Firewall;
