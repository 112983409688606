/* eslint-disable no-restricted-globals */
import {
  Dispatch,
  SetStateAction,
  createContext,
  useContext,
  useState,
  useEffect,
} from 'react';

interface IRoute {
  readonly path: string | undefined;
  setPath: Dispatch<SetStateAction<string | undefined>>;
}

const RouteContext = createContext<IRoute>({
  path: undefined,
  setPath: () => {},
});

const useRouteContext = () => {
  return useContext(RouteContext);
};

const RouteContextProvider = ({ children }: { children: React.ReactChild }) => {
  const [path, setPath] = useState<string>();
  const [isForward, setIsForward] = useState<boolean>(false);

  useEffect(() => {
    // console.log('RouteContext useEffect!!!');

    // console.log(window.location.pathname);

    setPath(window.location.pathname);
    // document.title = 'title';
  }, []);

  useEffect(() => {
    // console.log('RouteContext useEffect path!!!');

    // console.log({ path });

    history.pushState(undefined, 'title', path);
    // document.title = 'new title';
    path && setIsForward(true);
  }, [path]);

  return (
    // the Provider gives access to the context to its children
    <RouteContext.Provider value={{ path, setPath }}>
      {isForward && children}
    </RouteContext.Provider>
  );
};

export { RouteContextProvider, useRouteContext };
