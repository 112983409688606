const isProduction = process.env.NODE_ENV !== 'development';

const info = (message: string, extra?: string) => {
  if (isProduction) {
    return;
  }

  extra
    ? console.log(`%c ${message}`, 'color:blue', extra)
    : console.log(`%c ${message}`, 'color:blue');
};

const error = (message: string, extra?: string) => {
  if (isProduction) {
    return;
  }

  extra
    ? console.log(`%c ${message}`, 'color:red', extra)
    : console.log(`%c ${message}`, 'color:red');
};

const getStackTrace = function (obj: any) {
  // let obj: any = {};
  Error.captureStackTrace(obj, getStackTrace);
  return obj.stack;
};

const debug = (value: [] | {}) => {
  if (isProduction) {
    return;
  }

  console.trace(`%c ${JSON.stringify(value, undefined, 2)}`, 'color:darkblue');
  console.log(`%c ${JSON.stringify(value, undefined, 2)}`, 'color:darkblue');
};

export { info, error, debug, getStackTrace };
